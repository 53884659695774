import * as React from "react"
import { Link, graphql } from "gatsby"

import Bio from "../components/bio"
import Layout from "../components/layout"
import Seo from "../components/seo"


const showImage = () => {
  const gallery = document.querySelector('.gallery-modal');
  const images = document.querySelectorAll('.gatsby-resp-image-image');
  const galleryContent = document.querySelector('.gallery-content');

  images.forEach(image => image.addEventListener("click", (e) => {
    e.preventDefault();
    const cloneImage = image.cloneNode(true);
    cloneImage.classList.remove('gatsby-resp-image-image');
    cloneImage.style = null;
    cloneImage.sizes = null;
    galleryContent.append(cloneImage);
    gallery.style.display = "block";
    console.log(image)
  }))
};

const closeImage = () => {
  const gallery = document.querySelector('.gallery-modal');
  const galleryContent = document.querySelector('.gallery-content');
  gallery.style.display = "none";
  galleryContent.innerHTML = "";
}

const downHandler = ({ key }) => {
  if (key === "Escape") {
    closeImage();
  }
}

const onImageClose = () => {
  window.addEventListener("keydown", downHandler);
  document.querySelector('.close-icon').addEventListener("click", closeImage)
  document.querySelector('.gallery-modal').addEventListener("click", closeImage)
}

const BlogPostTemplate = ({ data, location }) => {
  React.useEffect(() => {
    onImageClose();
    showImage();
    
    // Remove event listeners on cleanup
    return () => {
      window.removeEventListener("keydown", downHandler);
    };
  }, []);
  const post = data.markdownRemark
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const { previous, next } = data

  const cover = post.frontmatter.cover_image.includes('webm') ?
    <video autoPlay muted loop>
      <source src={`../${post.frontmatter.cover_image}`} type="video/webm" />
      Your browser does not support the video tag.
    </video> : <img className="post-item-cover-image" src={`../${post.frontmatter.cover_image}`} alt="cover" />;

  // console.log(post.html)
  return (
    <Layout location={location} title={siteTitle}>
      <Seo
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
        cover_image={post.frontmatter.cover_image}
        slug={post.fields.slug}
      />
      <article
        className="blog-post"
        itemScope
        itemType="http://schema.org/Article"
      >
        <header>
          <h1 itemProp="headline">{post.frontmatter.title}</h1>
          <p>{post.frontmatter.date}</p>
        </header>
        {cover}
        <section
          dangerouslySetInnerHTML={{ __html: post.html }}
          itemProp="articleBody"
        />
        
        <div className="gallery-modal">
          <div className="gallery-close"><span className="close-icon">x</span></div>
          <div className="gallery-content"></div>
        </div>

        <hr />
        <footer>
          <Bio />
        </footer>
      </article>
      <nav className="blog-post-nav">
        <ul
          style={{
            display: `flex`,
            flexWrap: `wrap`,
            justifyContent: `space-between`,
            listStyle: `none`,
            padding: 0,
          }}
        >
          <li>
            {previous && (
              <Link to={previous.fields.slug} rel="prev">
                ← {previous.frontmatter.title}
              </Link>
            )}
          </li>
          <li>
            {next && (
              <Link to={next.fields.slug} rel="next">
                {next.frontmatter.title} →
              </Link>
            )}
          </li>
        </ul>
      </nav>
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug(
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      html
      fields {
        slug
      }
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        cover_image,
      }
    }
    previous: markdownRemark(id: { eq: $previousPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
    next: markdownRemark(id: { eq: $nextPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
  }
`
